.d-profile-wrapper {
  width: 100%;
  margin: auto 0;
}

.d-profile-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(var(--primary-color), white);
  margin-bottom: 30px;
}

.d-profile-heading h1 {
  font-size: 35px;
  padding-top: 20px;
}

.d-profile-heading p {
  font-size: 20px;
}

.d-profile-pic {
  display: flex;
  justify-content: center;
}

.d-profile-image {
  width: 50%;
  position: relative;
}

.d-profile-image img {
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
}

.d-profile-image svg {
  font-size: 8rem;
  color: var(--secondary-color);
  cursor: pointer;
}

.d-account-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.d-profile-wrapper form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.d-profile-info {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.d-profile-wrapper form .form-select {
  max-width: 90%;
  margin: 0 auto;
}
/*  */

.d-profile-info-xtra .d-input-box {
  padding: 0 0.5rem;
}

.d-phone-box {
  display: flex;
}

.d-phone-box .phone {
  max-width: 80px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.d-phone-box input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 0 !important;
}

.input-place input::placeholder{
  font-size: small;
}

.d-input-box {
  position: relative;
  width: 100%;
}

.d-input-box input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--primary-color);
  background: transparent;
  border-radius: 5px;
  font-size: 20px;
  outline: none;
}

.d-input-box span {
  position: absolute;
  left: 0;
  padding: 8px;
  font-size: 20px;
  pointer-events: none;
}

.d-input-box input:valid ~ span,
.d-input-box input:focus ~ span {
  color: var(--primary-color);
  transform: translateX(10px) translateY(-20px);
  font-size: 15px;
  background-color: var(--white);
  transition: var(--transition-all);
}

.d-input-box input:focus {
  border: 1px solid var(--primary-color);
}

.d-gender-button {
  display: flex;
  gap: 20px;
}

.d-gender-button button {
  width: 90px;
  height: 80px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  font-size: 20px;
  box-shadow: var(--box-shadow);
}

.d-gender-button button:hover,
.male-gender,
.female-gender {
  border: 1px solid var(--hover-color) !important;
  background-color: var(--hover-color) !important;
  transition: var(--transition-all);
  color: var(--white);
}

.d-change-password-btn {
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  border: 1px solid var(--black);
}

.d-change-password-btn:hover {
  border: 1px solid var(--hover-color);
}

.d-note-before {
  display: flex;
  text-align: center;
  font-size: 20px;
  padding: 5px;
}

.d-button-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 70px;
}

.d-save-cancel-button {
  display: flex;
  gap: 20px;
}

.d-save-cancel-button button {
  width: 90px;
  height: 50px;
  font-size: 20px;
  border: 1px solid var(--black);
  background-color: transparent;
  color: var(--black);
}

.d-save-cancel-button button:hover {
  border: 1px solid var(--hover-color);
  transition: var(--transition-all);
}

.d-delete-button button {
  position: relative;
  padding: 10px;
  font-size: 20px;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
}

.d-delete-button button:hover {
  border: 1px solid var(--hover-color);
  transition: var(--transition-all);
}

@media screen and (min-width: 375px) {
  .d-input-box {
    position: relative;
    width: 280px;
  }
}

@media screen and (min-width: 768px) {
  .d-profile-heading p {
    padding: 0 70px;
  }

  .d-profile-image {
  max-width: 20%;
}

.d-profile-pic .pic {
  max-width: 11%;
}

  .d-profile-info {
    flex-direction: row;
  }

  .d-profile-wrapper form {
    align-items: flex-start;
    padding: 20px;
  }

  .d-profile-info-xtra {
    gap: 20px;
  }

  .d-profile-info-xtra .d-countries {
    max-width: 20% !important;
  }

  .d-button-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .d-gender-button {
    font-size: 25px;
  }

  .d-gender-button button {
    width: 150px;
    height: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .d-profile-info {
    display: flex;
    width: 100%;
  }

  /* .d-profile-info-xtra{
    flex-direction: ;
  } */

  .d-input-box {
    width: 100%;
  }

  #email {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .d-profile-wrapper form {
    max-width: 90%;
    margin: 0 auto;
  }
}
