.d-card {
  position: relative;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  padding-top: 10px;
  text-align: center;
  transition: var(transition-all);
}

.d-card-img {
  position: relative;
  width: 90%;
  overflow: hidden;
  border-radius: 5px;
  margin: 0 auto;
  border: 1px solid var(--primary-color);
}

.d-card-img img {
  aspect-ratio: 1;
  object-fit: contain;
}

.d-admin-card {
  aspect-ratio: 1.5;
}

.d-card-img button {
  position: absolute;
  right: 10px;
  top: 5px;
  color: var(--secondary-color);
  font-size: 1.5rem;
  border: none;
  background-color: transparent;
  display: none;
}

.d-card-img img {
  transition: transform 0.5s ease-in-out;
}

.d-card:hover .d-card-img button {
  display: block;
}

.d-card-details {
  text-align: center;
  padding: 10px 5px;
}

.info-deet {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0;
}

.d-card .book-modal {
  color: var(--black);
}

.d-card .p-book-deet {
  margin-top: 0.4rem;
  text-align: left;
}

.d-card .submit-cancel-Btn {
  margin-top: 0.8rem;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .d-card-img button {
    display: block;
  }
}
