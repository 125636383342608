.login .login-form-wrap {
  color: black;
  width: 50%;
  margin: 4rem auto;
  padding: 0 0 1.5rem;
  align-items: center;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
}

.login .login-form {
  width: 55%;
  margin: 2rem auto;
}

.login .recaptcha {
  height: 80px;
  align-items: center;
  background-color: #f9f9f9;
}

.login .form-group-s .form-control-s {
  background-color: none;
  border-radius: none;
  border: 1px solid var(--green-600);
  height: 55px;
}

.login .form-group-s .form-control-s::placeholder {
  color: black;
}

.login img {
  width: 30px;
  margin: 0 auto;
}

.login .form-check-label {
  font-size: smaller;
  align-items: center;
}

.login .form-check .form-check-input {
  vertical-align: middle;
  font-size: smaller;
  width: 2em;
  height: 2em;
  margin-left: 0;
  border: 1px solid var(--green-500);
}

.login .form-check .form-check-input:hover {
  border: 1px solid var(--tertiary-color);
}

.login .form-title {
  font-weight: bolder;
  font-size: 30px;
  margin: 2rem auto;
}

.form label {
  font-size: smaller;
}

.login .form-check-label {
  margin-left: 10px;
  margin-top: 5px;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-capcha div {
  width: 100% !important;
}

.login-capcha iframe {
  width: 100%;
}

.login .form-button-s {
  background-color: var(--green-500);
  font-size: 16px;
  color: var(--white);
  border: none;
  width: 100%;
  margin: 10px auto;
  height: 55px;
}

.login .form-button-s:hover {
  color: var(--tertiary-color);
}

.login .social-images {
  width: 80%;
  margin: 0 auto;
}

.login .social-images .social-images-wrap {
  width: 100%;
  margin: 1rem auto;
}

small {
  font-size: 10px;
}

@media screen and (max-width: 767px) {
  .login .login-form-wrap {
    width: 100%;
  }

  .login img {
    width: 20px;
  }

  .login .form-title {
    font-size: 20px;
  }

  .login .form-check {
    padding-left: 0.5em;
  }
  .login .form-check .form-check-input {
    width: 1em;
    height: 1.2em;
  }

  .login .form-check-label {
    font-size: 10px;
  }

  .login .form-group-s .form-control-s::placeholder {
    font-size: 14px;
  }

  .login .social-images {
    width: 100%;
  }

  .login .signin {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .login .login-form {
    width: 85%;
  }
}
