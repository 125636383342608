.d-table {
  width: 100%;
  text-align: center;
}

.d-table thead {
  border-bottom: 2px solid #eee;
}

.d-table tr {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 4fr 3fr 2fr;
  width: 100%;
}

.d-table tbody tr {
  padding: 5px 5px 5px 0;
  margin: 5px 0 5px;
  line-height: 2.5;
}

.d-table tbody tr:nth-of-type(2n) {
  background: var(--green-100);
}

.d-table tbody tr:nth-of-type(2n + 1) {
  background: var(--white);
}

.d-table {
  .d-status-success {
    color: var(--green-200);
  }

  .d-status-pend {
    color: var(--pend);
  }

  .d-status-fail {
    color: var(--fail);
  }
}

.d-table-modal {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.285);
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-modal-ticket {
  background-color: #eee;
  width: 100%;
  max-width: 80%;
  padding: 20px 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: normal !important;
}

.d-table-container .d-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  gap: 10px;
}

.d-table-container .d-pagination button {
  all: unset;
  color: var(--white);
  width: 30px;
  aspect-ratio: 1;
  background-color: var(--green-200);
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.d-table-container .d-pagination .pagination-active {
  opacity: 0.75;
}

@media screen and (min-width: 730px) {
  .d-table .d-mobile-row {
    display: none;
  }
}

@media screen and (max-width: 730px) {
  tbody .d-desktop-row,
  thead {
    display: none;
  }

  .d-table .d-mobile-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    font-size: 12px;
    width: 100%;

    > td p {
      font-weight: 700;
    }

    > td .d-mobile-success {
      color: var(--green-200);
    }
    > td .d-mobile-pend {
      color: var(--pend);
    }
    > td .d-mobile-fail {
      color: var(--fail);
    }
  }
}
