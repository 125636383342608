.d-wallets {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
  margin: 20px auto;
  padding: 0 10px;
  width: 100%;
}

.d-wallets .d-spent,
.d-wallets .d-bal {
  box-shadow: var(--box-shadow);
  padding: 20px;
  border-radius: 20px;
  transition: var(--transition-all);
}

.d-wallets .d-spent:hover,
.d-wallets .d-bal:hover {
  box-shadow: var(--box-shadow-hover);
}

.d-table-container {
  width: 100%;
  max-width: 90%;
  margin: 20px auto;
  padding: 20px 10px;
  overflow-x: auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.d-table-header h3 {
  text-align: center;
  margin: 20px 0;
}


.d-table-container .error {
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (min-width: 550px) {
  .d-wallets {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .d-wallets {
    max-width: 80%;
    margin: 60px auto;
  }

  .d-wallets strong {
    font-size: var(--ptext2);
  }
}

@media screen and (min-width: 1200px) {
  .d-wallets {
    max-width: 70%;
  }
}
