.change-pass-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.change-password {
  line-height: 40px;
  width: 90%;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 20px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  transition: var(--transition-all);
}

.change-password h1 {
  padding: 10px;
  color: var(--black);
}

form label {
  padding: 0;
  color: var(--black);
}

form input {
  width: 90%;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid var(--black);
  outline: none;
  box-shadow: var(--box-shadow);
}

.submit-cancel-Btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.submit-cancel-Btn button {
  padding: 0 20px;
  border-radius: 4px;
  border: 1px solid var(--black);
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.submit-cancel-Btn button:hover {
  border: 1px solid var(--hover-color);
}

.submit-cancel-Btn .active {
  background: var(--primary-color);
}

@media screen and (min-width: 768px) {
  .pass-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }

  .change-password {
    width: 90%;
    align-items: center;
    /* padding: 20px 0px; */
  }

  form input {
    width: 100%;
    padding: 0 10px;
  }

  .submit-cancel-Btn {
    justify-content: center;
    gap: 20px;
  }
}
