.section-providers {
  padding: 0 0.5rem;
}

.section-providers .back {
  color: var(--black);
}

.section-providers .back:hover {
  color: var(--tertiary-color);
}

.section-providers .search-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.section-providers .search-container input {
  padding: 1rem 3rem;
  width: 350px;
}

.search-container input {
  text-transform: lowercase;
}

.search-container input::placeholder {
  text-transform: capitalize;
}

.section-providers .search-container input::placeholder {
  color: var(--black);
  padding-left: 1rem;
}

.section-providers .search-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  position: absolute;
  /* margin-bottom:2rem; */
  padding-left: 0.5rem;
  margin-left: 1rem;
}

.section-providers .card-image img {
  padding: 0;
  margin: 0;
  width: 100px;
  min-height: 100px;
}

.section-providers .card-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 1.5rem;
  margin: 0 auto;
}

.section-providers .card-body {
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
  border: 5px solid whitesmoke;
  text-align: center;
}

.section-providers .card-body .card-image {
  padding: 0.5rem;
}

.section-providers .card-body img {
  width: 100%;
  aspect-ratio: 1.2;
  object-fit: contain;
}

.section-providers .card-footer {
  border-top: 1px solid var(--tertiary-color);
  background-color: white;
}

.section-providers .card-footer .card-text {
  color: var(--tertiary-color);
  font-size: 14px;
}

.section-providers .card-footer .card-text:hover {
  color: var(--green-500);
}

@media screen and (min-width: 750px) {
  .p-providers .card-section {
    grid-template-columns: repeat(4, 1fr);
  }

  .providers-title {
    font-size: 2.5rem !important;
  }
}

@media screen and (min-width: 1000px) {
  .p-providers .card-section {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .section-providers .search-container input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 170px;
  }

  .section-providers .search-container input::placeholder {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .section-providers {
    max-width: 90%;
    margin: 0 auto;
  }
}
