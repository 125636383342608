.navigate {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.navigate .search {
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  width: 30%;
  padding: 0.5rem 0;
}

.navigate .back {
  color: var(--black);
}

.navigate a:hover {
  color: var(--tertiary-color);
}

.navigate .search-container {
  display: flex;
  align-items: center;
}

.navigate .search-container input {
  padding: 1rem 3rem;
  width: 350px;
}

.navigate .search-container input::placeholder {
  color: var(--black);
  padding-left: 1rem;
}

.navigate .search-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  position: absolute;
  padding-left: 0.5rem;
  margin-left: 1rem;
}

.p-exams {
  margin-bottom: 50px;
}

.exam-card-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 1.5rem;
  padding: 0 0.9rem;
  max-width: 90%;
  margin: 0 auto;
}

.exam-card-section h5{
  font-size: 1rem;
}

.exam-card-section .card-body {
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
  border: 5px solid whitesmoke;
  text-align: center;
  /* max-height: 200px; */
}

.exam-card-section .card-body .card-image {
  padding: 0.5rem;
}

.exam-card-section .card-body .card-image img {
  width: 100%;
  aspect-ratio: 1.2;
  object-fit: contain;
}

.exam-card-section .card-footer {
  border-top: 1px solid var(--tertiary-color);
  background-color: white;
}

.exam-card-section .card-footer .card-text {
  color: var(--tertiary-color);
  font-size: 14px;
}

.exam-card-section .card-footer .card-text:hover {
  color: var(--green-500);
}

@media screen and (min-width: 750px) {
  .exam-card-section {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1000px) {
  .exam-card-section {
    grid-template-columns: repeat(5, 1fr);
  }

   .exam-card-section {
    max-width: 80%;
    margin: 0 auto;
   }
}

@media screen and (max-width: 767px) {
  .navigate .search-container input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 170px;
  }

  .navigate .search-container input::placeholder {
    font-size: 14px;
  }
}
