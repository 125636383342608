.first-nav {
  background-color: var(--black);
  margin-top: 0;
  padding: 0.5rem 0;
}

.first-nav a {
  color: white;
}

.first-nav .nav {
  align-items: center;
  align-content: center;
}

.first-nav .brand {
  font-size: 16px;
}

.first-nav .brand-icon {
  font-size: 22px;
}

.first-nav .item-list {
  display: flex;
  align-items: center;
}

.first-nav .user-auth {
  display: none;
}

.first-nav .nav-list {
  font-size: 16px;
  margin-right: 1rem;
}

.first-nav .icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
}

.first-nav .icon .image {
  width: 15px;
  height: 15px;
}

.first-nav .border-left {
  border-right: 2px solid var(--white);
  height: 3vh;
}

.p-nav-logo {
  width: 20%;
}

.p-nav-logo img {
  width: 100%;
}

.nav-logo .mail-icon {
  font-size: 20px;
}

.to-dash {
  border: 1px solid var(--white);
  background-color: var(--green-500);
  padding: 4px 10px;
  margin: 0 10px 0 0;
  transition: var(--transition-all);
}

.to-dash:hover {
  background-color: var(--white);
  border: 1px solid var(--green-500);
  color: black;
}

.second-nav {
  background-color: var(--green-500);
  transition: all 0.3s ease;
  height: 55px;
}

.second-nav .button {
  border: 1px solid #dedede;
  border-radius: 1px;
  padding: 0.2rem 2.2rem;
  font-size: 18px;
  color: var(--white);
  background-color: none;
  margin-top: 0;
  margin-right: 1rem;
}

.second-nav .button:hover {
  color: var(--tertiary-color);
}

.responsive-navbar-nav .nav-items {
  align-items: center;
}

.second-nav .nav-links,
.second-nav .nav-change {
  font-size: 18px;
  color: var(--white);
  margin-right: 2rem;
}

.second-nav .nav-change,
.second-nav .nav-links:hover {
  color: var(--tertiary-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: 2rem;
}

.p-navbar-scroll {
  position: sticky;
  z-index: 10;
  top: -1px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.n-mobile-btn{
  display: none;
}

@keyframes sticky {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 767px) {
  .first-nav .brand {
    font-size: 14px;
  }

  .first-nav .item-list {
    display: none;
  }

  .n-mobile-btn{
    display: block;
  }

  .first-nav .user-auth {
    display: block;
    color: white;
  }

  .first-nav .user-auth:hover {
    color: rgba(255, 255, 255, 0.39);
  }

  .first-nav .nav-list {
    font-size: 14px;
  }

  .first-nav .icon {
    font-size: 12px;
    margin-top: 0;
  }

  .nav-logo img {
    width: 100px;
  }

  .nav-logo .mail-icon {
    font-size: 20px;
  }

  .nav-logo h6 {
    font-size: 14px;
  }

  .nav-logo span {
    font-size: 11px;
  }

  .second-nav.nav-toogle {
    width: 100%;
    border: none;
    margin: 0;
    align-items: left;
    padding: 5px 0;
  }

  .second-nav .nav-links {
    color: var(--white);
    background-color: none;
  }

  .second-nav .nav-links:hover {
    color: var(--tertiary-color);
  }
}

@media screen and (max-width: 992px) {
  .second-nav .container-fluid {
    padding: 0;
    z-index: 2;
  }

  .second-nav .toggle {
    margin: 0.3rem 1rem 0;
  }

  .navbar-collapse {
    background-color: var(--black);
    padding: 1rem;
  }

  .navbar-toggler-icon {
    background-image: url("./ham.png");
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0.03rem;
  }

  .second-nav .toggle {
    border: 0;
    color: var(--white);
    margin-left: auto;
  }

  .second-nav .nav-items {
    align-items: start;
    gap: 10px;
    padding: 0 0 20px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }

  .navbar-collapse {
    background-color: var(--green-500);
    padding: 0.5rem 0;
  }

  .second-nav .nav-items {
    padding-bottom: 0;
  }
}
