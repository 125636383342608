/* section-one */
.section-one-main {
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  top: 0;
  left: 0;
  z-index: -1;
  animation: slide 10s infinite alternate;
}

@keyframes slide {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}


.section-one-main::before {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
  background-color: var(--black);
}

.section-one-main .text {
  color: var(--white);
  font-size: 1em;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.section-one-main .text h1 {
  font-size: 4rem;
  font-weight: bolder;
}

.section-one-main .p-text {
  width: 70%;
  font-size: 1.375rem;
}

.section-one-main .button {
  padding: 0.5rem 2rem;
  color: var(--white);
}

.section-one-main .button:hover {
  color: var(--tertiary-color);
}

/* section-two */
.section-two-main-text {
  padding: 0 0.9rem;
}

.section-two-main h4 {
  font-size: 2rem;
}

.section-two-main hr {
  color: var(--tertiary-color);
  font-weight: bolder;
}

.section-two-main img {
  padding: 0;
  margin: 0;
  width: 100px;
  min-height: 100px;
}

.p-providers .card-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 1rem;
  padding: 0 0.4rem;
  max-width: 95%;
  margin: 0 auto;
}

.section-two-main .card-body {
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
  border: 5px solid whitesmoke;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.section-two-main .card-body .card-image {
  padding: 0.5rem;
}

.section-two-main .card-body img {
  width: 100%;
  aspect-ratio: 1.5;
  object-fit: contain;
}

.section-two-main .card-footer {
  border-top: 1px solid var(--tertiary-color);
  background-color: white;
}

.section-two-main .card-footer .card-text {
  color: var(--tertiary-color);
  font-size: 14px;
}

.section-two-main .card-footer .card-text:hover {
  color: var(--green-500);
}

.section-two-main .button {
  width: 20%;
  align-items: start;
  margin-top: 5rem;
}

.section-two-main .button:hover {
  color: var(--green-500);
}

/* section-three-main */
.section-three-main {
  background-color: var(--green-100);
  margin-top: 4rem;
  opacity: 5;
  position: relative;
}

.section-three-main image {
  height: 50vh;
}

hr.section-three-main-line {
  border: 3px solid var(--secondary-color);
  z-index: 1;
  width: 15%;
  z-index: 1;
  margin-left: 5rem;
}

.section-three-main .text {
  margin: 0 auto;
}

.section-three-main .text h4 {
  width: 95%;
  font-size: 36px;
  padding-top: 2rem;
  color: black;
  margin: 0 auto;
}

.section-three-main .text p {
  width: 95%;
  padding-top: 1rem;
  color: black;
  margin: 0 auto;
  font-size: 1.4rem;
}

.section-three-main .exam-search-buttons {
  margin-top: 4rem;
  padding: 0 1rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.exam-search-buttons select {
  width: 33%;
  height: 3.5rem;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}

.p-custom-select {
  appearance: none;
  background-color: #ffffff;
}

.filter-drop {
  width: 33%;
  position: relative;
}

.section-three-main .exam-search-buttons .keyword {
  width: 100%;
  height: 3.5rem;
  padding: 0 2rem;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}

.exam-search-buttons input::placeholder {
  color: var(--black);
}

.p-dropdown {
  position: absolute;
  width: 100%;
  background-color: white;
}

.p-dropdown-option {
  margin: 0.5rem;
  padding: 0.5rem 0;
  cursor: pointer;
  background-color: var(--primary-color);
  transition: var(--transition-all);
}

.p-dropdown-option:hover {
  background-color: var(--white);
}

.exam-search-buttons button {
  border: 1px solid var(--green-500);
  border-radius: 5px;
  padding: 0 2rem;
  color: white !important;
  background-color: black;
  transition: var(--transition-all);
  height: 3.5rem;
  width: 33%;
}

.exam-search-buttons button:hover {
  background-color: var(--green-500);
}

@media screen and (min-width: 375px) {
  .p-providers .card-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 750px) {
  .p-providers .card-section {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1000px) {
  .p-providers .card-section {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .section-one-main .p-text {
    width: 90% !important;
    font-size: 0.97rem !important;
  }

  .section-one-main .text {
    margin-top: 0;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 767px) {
  .section-one-main {
    height: 550px;
  }

  .section-one-main .text {
    width: 90%;
  }

   .section-one-main .break {
    display: none;
   }

  .section-two-main h5 {
    font-size: 2rem;
  }

  .section-two-main-text {
    max-width: 80%;
    margin: 0 auto;
  }

  .section-two-main-text p {
    text-align: center;
  }

  .p-study {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

  .p-study .image img {
    aspect-ratio: 1.5;
  }

  .section-three-main .text h4 {
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .section-one-main .text h1 {
    font-size: 2rem;
  }

  .section-one-main h1 {
    font-size: 18px;
  }

  .section-one-main .button {
    padding: 0.5rem 1rem;
  }

  .section-one-main .p-text {
    width: 70%;
  }

  .section-two-main .button {
    width: 100%;
    margin-top: 2rem;
  }

  .section-three-main {
    margin-top: 2rem;
  }

  .section-three-main .text {
    width: 100%;
  }

  .section-three-main .text h4 {
    font-size: 1.5rem;
  }

  .section-three-main .text p {
    font-size: 1.2rem;
  }

  hr.section-three-main-line {
    margin-left: 2rem;
  }

  .section-three-main .search-main button {
    margin-bottom: 1rem;
  }

  .section-three-main .exam-search-buttons {
    margin-top: 2rem;
    flex-direction: column;
    gap: 20px;
    padding: 0 1rem;
  }

  .exam-search-buttons select {
    width: 100%;
  }

  .section-three-main .exam-search-buttons .keyword {
    padding: 0 2rem;
    width: 100%;
  }

  .section-three-main .exam-search-buttons .button,
  .filter-drop {
    width: 100%;
  }

  .section-three-main .mx-md-3 {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .section-one-main {
    top: -55px;
  }

  .section-two-main h4 {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .section-one-main {
    top: 0;
  }

  .p-providers .card-section {
    max-width: 90%;
  }

  .section-three-main .exam-search-buttons {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 1.2rem;
  }
}

@media screen and (min-width: 1000px) {
  .section-two-main h4 {
    font-size: 3rem;
  }

  .section-two-main-text p {
    font-size: 1.5rem;
  }
}
