@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

:root {
  --primary-color: #aec7aa;
  --secondary-color: #fea800;
  --white: #ffffff;
  --black: #000000;
  --hover-color: #fec700;
  --light-text-color: #777a76;
  --tertiary-color: #ffaa33;
  --post-tertiary-color: #e88600;
  --green-100: #e3efe1;
  --green-200: #91ec80;
  --green-400: #52682d;
  --green-500: #4c6021;
  --green-600: #264b20;
  --fail: #fd0000;
  --pend: #b5506172;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 0 0 10px rgba(0, 0, 0, 0.783);
  --link-text: 1.2rem;
  --ptext2: 1.5rem;
  --transition-all: all 0.5s ease-in-out;
  --transition-bg: background 0.5s ease-in-out;
  --brown: #e68d00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading-logo {
    width: 30px !important;
    margin: 0 auto;
}
