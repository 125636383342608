.blog-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  gap: 68px;
}

.blog-image-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.blog-image {
  background-color: rgba(0, 0, 0, 0.396);
  width: 100%;
}

.blog-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: auto 300 / 251;
}

.blog-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin: 0;
}

.blog-social-icons {
  display: flex;
  gap: 0.5rem;
  margin: 0.9rem 0 1.2rem;
}

.blog-social-icons .blog-social-icon {
  background-color: var(--green-500);
  padding: 0.1rem 2rem;
  color: white;
}

.read-more {
  color: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  padding: 0.5rem;
  transition: var(--transition-all);
}

.read-more:hover svg {
  transform: translateX(3px);
}

.span-user {
  margin-top: 1rem;
  gap: 0.7rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.span-user-text {
  flex-wrap: wrap;
  display: flex;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 0.7em;
  letter-spacing: 0.5px;
  color: gray;
  gap: 4px;
  margin: 0 0 .5rem;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.pagination button{
  background-color: var(--primary-color);
}

.pagination button,
.pagination span {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid var(--green-500);
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
}

.side-post {
  border-left: 1px solid rgba(0, 0, 0, 0.396);
  width: 100%;
  padding: 0.5rem 1rem;
}

.side-post-wrap {
  padding-left: 1rem;
}

.side-post-head {
  font-size: 14px;
  font-weight: bold;
}

.recents {
  margin: 15px 0 0;
  border-bottom: 1px solid #eaecee;
}

.side-post-date {
  font-size: 14px;
}

.side-post-span {
  color: var(--tertiary-color);
  font-size: 14px;
}

@media screen and (min-width: 569px) {
  .blog-image-text {
    flex-direction: row;
    align-items: center;
  }

  .blog-text {
    flex: 1;
    max-width: 100%;
  }

  .blog-image {
    width: 30%;
    flex-basis: 30%;
  }
}

@media screen and (min-width: 769px) {
  .blog-area {
    flex-direction: row;
    gap: 1rem;
    width: 95%;
  }

  .left-blog-area {
    flex-basis: 75%;
    max-width: 75%;
  }

  .side-post {
    flex-basis: 25%;
    max-width: 25%;
  }
}
