.d-lib-h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.d-lib-books {
  background-color: var(--white);
  padding: 20px 10px;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
  border-radius: 20px; 
}

.d-lib-cards {
  display: grid;
  grid-template-columns: repeat(1, 180px);
  grid-gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.d-lib-no-books {
  text-align: center;
  font-size: 1.2rem;
  color: #aaa;
  margin: 20px 0;
}

@media screen and (min-width: 375px) {
  .d-lib-cards {
    grid-template-columns: repeat(2, 120px);
  }
}

@media screen and (min-width: 425px) {
  .d-lib-cards {
    grid-template-columns: repeat(2, 140px);
  }
}

@media screen and (min-width: 750px) {
  .d-lib-cards {
    grid-template-columns: repeat(3, 180px);
  }
}

@media screen and (min-width: 850px) {
  .d-lib-h2 {
    font-size: 2rem;
    text-align: left;
    padding: 0 0 0 2rem;
    margin: 2rem 0;
  }

  .d-lib-cards {
    grid-template-columns: repeat(3, 180px);
  }
}

@media screen and (min-width: 1280px) {
  .d-lib-cards {
    grid-template-columns: repeat(4, 180px);
  }
}
