.d-searchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin: 0 0 20px auto;
  width: 200px;
  padding: 10px 0;
}

.d-searchbar svg {
  font-size: 1.5rem;
  color: #aaa;
  margin: 0 10px;
}

.d-searchbar input {
  border: none;
  outline: none;
  font-size: 1.2rem;
  width: 100%;
  background: transparent;
}

.d-searchbar input::placeholder {
  color: #aaa;
}
