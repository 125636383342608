.pa-footeer {
  background-color: #000000de;
  background-image: url("../../static/pagefooter-foot.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.pa-footer {
  padding-top: 10rem;
}
.pa-footer .text {
  width: 25%;
  color: var(--white);
}

.pa-footer .text h6 {
  border-bottom: 3px solid var(--tertiary-color);
  width: 180px;
  padding-bottom: 0.5rem;
}

.pa-footer .text .icon-text a {
  display: flex;
  color: var(--white);
}

.pa-footer .text .icon-text .icon {
  font-size: 22px;
}

.pa-footer .text .icon-text p {
  font-size: 16px;
}

.pa-footer .icons-icon {
  margin-top: 1.5rem;
  max-width: 25%;
  margin: 1rem auto 0;
}

.pa-footer .icons-icon a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1rem 1.8rem;
  color: var(--white);
  gap: 3px;
  font-size: 1.1rem;
  transition: var(--transition-all);
}

.pa-footer .icons-icon a:hover,
.pa-footer .text .icon-text a:hover {
  color: var(--primary-color);
}

.pa-footer .icons-icon a p {
  margin-bottom: 0;
}

.pa-footer .icons {
  width: 50%;
  color: white;
  display: block;
  font-size: 30px;
}

.pa-footer .line {
  border-bottom: 3px solid var(--secondary-color);
  z-index: 1;
  width: 15%;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .pa-footer {
    padding-top: 4rem;
    display: block;
  }

  .pa-footer .logo {
    width: 200px;
    margin-right: 0;
  }
  .pa-footer .text {
    width: 100%;
  }

  .pa-footer .icons {
    margin-top: 1rem;
  }

  .pa-footer .icons-icon {
    width: 90%;
    max-width: 100%;
    display: flex;
    margin-top: 0.5rem;
    flex-direction: column;
  }

  .pa-footer .line {
    width: 50%;
  }
}
