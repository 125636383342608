.d-payment {
  padding: 10px;
  text-align: center;
  font-size: 1rem;
}

.d-payment h3,
.d-payment h4 {
  font-size: 1rem;
}

.d-payment-body {
  background-color: var(--white);
  padding: 10px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.d-payment-modes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
}

.d-payment-modes .payment-mode {
  width: 100px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
}

.d-payment-modes .payment-mode img {
  aspect-ratio: 1.7;
}

.d-payment-modes .payment-active {
  border: 2px solid var(--black);
}

.d-payment .d-amounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 20px auto;
}

.d-payment .d-amounts button {
  width: 70px;
  font-size: 12px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  transition: var(--transition-all);
  padding: 5px 0;
}

.d-payment .d-amounts .selected,
.d-payment .d-amounts input:focus {
  border: 1px solid var(--secondary-color);
}

.d-payment .d-amounts input {
  outline: none;
  border-radius: 20px;
  padding: 5px 10px;
  text-align: center;
  border: 2px solid var(--green-100);
}

.d-payment-btn .d-sec-btn {
  all: unset;
  width: 150px;
  margin: 0 0 10px;
  background-color: var(--secondary-color);
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  transition: var(--transition-all);
  cursor: pointer;
}

.d-payment-btn button:hover,
.d-payment .d-amounts button:hover {
  background-color: var(--hover-color);
  color: white;
}

@media screen and (min-width: 768px) {
  .d-payment {
    margin: 20px auto;
  }

  .d-payment h3,
  .d-payment h4,
  .d-payment p,
  .d-payment .d-payment-btn {
    text-align: left;
  }

  .d-payment h3,
  .d-payment h4 {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .d-payment .d-payment-modes,
  .d-payment .d-amounts {
    justify-content: flex-start;
    max-width: 80%;
  }

  .d-payment-body {
    padding: 20px;
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
  }

  .d-payment-btn .d-sec-btn {
    margin-left: 20px;
  }
}
