.d-overview h2 {
  text-align: center;
  margin: 20px 0;
}

.d-overview-wrapper {
  background-color: var(--white);
  padding: 20px 10px;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
}

.d-overview-cards {
  display: grid;
  grid-template-columns: repeat(1, 180px);
  grid-gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.d-overview-no-books {
  text-align: center;
  font-size: 1.2rem;
  color: #aaa;
  margin: 20px 0;
}

.d-overview-wrapper h3,
.d-admin-msg {
  padding: 0 20px;
}

.d-admin-cards{
  margin-top: 1rem;
}

@media screen and (min-width: 375px) {
  .d-overview-cards {
    grid-template-columns: repeat(2, 120px);
  }
}

@media screen and (min-width: 425px) {
  .d-overview-cards {
    grid-template-columns: repeat(2, 140px);
  }
}

@media screen and (min-width: 750px) {
  .d-overview-cards {
    grid-template-columns: repeat(3, 180px);
  }
}

@media screen and (min-width: 850px) {
  .d-overview-cards {
    grid-template-columns: repeat(3, 180px);
  }
}

@media screen and (min-width: 1280px) {
  .d-overview-cards {
    grid-template-columns: repeat(4, 180px);
  }
}
