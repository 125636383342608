.preview-header {
  background-image: linear-gradient(var(--brown), white, var(--brown));
  background-image: -o-linear-gradient(var(--brown), white, var(--brown));
  background-image: -moz-linear-gradient(var(--brown), white, var(--brown));
  background-image: -webkit-linear-gradient(var(--brown), white, var(--brown));
}

.preview-keyword,
.preview-keyword .keyword {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
}

.preview-keyword .keyword {
  flex-wrap: wrap;
  gap: 10px;
}

.preview-keyword p {
  margin: 0;
}

.preview-keyword .keyword p {
  border: 1px solid var(--green-500);
  color: var(--green-500);
  padding: 0.5rem 2rem;
}

.preview-auth-buy {
  display: flex;
  flex-direction: column-reverse;
  margin: 2rem auto;
  align-items: center;
  width: 90%;
}

.preview-auth {
  background-color: var(--black);
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10px;
}

.preview-auth a {
  color: var(--white);
  align-items: center;
  justify-content: center;
}

.preview-line {
  color: white;
  height: 1px;
  width: 55px;
  transform: rotate(90deg);
}

.preview-purchase {
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
}

.preview-purchase-text {
  border: 0.5px solid gray;
}

.preview-purchase-text p {
  font-size: 12px;
  margin: 0;
}

.preview-auth-buy .book-modal{
  color: var(--black);
}

.preview-auth-buy .book-modal .preview-text {
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .preview-keyword,
  .preview-keyword .keyword {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 600px) {
  .preview-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .p-book-img {
    flex-basis: 25%;
    max-width: 25%;
  }

  .p-book-img img {
    width: 100%;
  }

  .p-book-deet {
    flex-basis: 75%;
    max-width: 75%;
  }

  .preview-auth-buy {
    align-items: inherit;
    flex-direction: row;
  }

  .preview-auth {
    display: block;
  }

  .preview-line {
    color: white;
    width: 100%;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 800px) {
  .preview-auth-buy {
    max-width: 50%;
    margin: 2rem auto 2rem 2rem;
  }
}
